<template>
  <q-page class="accountPage">
    <div class="page-title">
      <div class="container">
        <div class="row items-center accountProfile">
          <div class="col-12 col-sm-6 col-md-9">
            <div class="accountTop">
              <div class="accountAvatar">
                <UserAvatar />
                <div
                  class="accountAgeVerify"
                  v-if="businessConfigurations.isStoreAgeRestricted"
                >
                  <template v-if="!customer.isVerifiedOnline">
                    <div class="text-center">
                      <q-icon name="error" class="text-warning" />
                      <div class="text-caption text-warning"
                        >Age not verified</div
                      >
                    </div>
                    <q-btn
                      unelevated
                      no-caps
                      flat
                      color="tertiary"
                      label="Verify"
                      class="text-underline no-hover"
                      padding="0"
                      @click="triggerEAIVFlow"
                    />
                  </template>
                  <div class="text-center" v-else>
                    <q-icon
                      name="check"
                      size=".65rem"
                      class="bg-green text-white q-pa-xs r-8"
                    />
                    <div class="text-caption text-green">Age Verified</div>
                    <q-btn
                      unelevated
                      no-caps
                      flat
                      color="tertiary"
                      label="Show age-verified QR"
                      class="text-underline no-hover text-caption"
                      padding="0"
                      @click="openAgeVerifiedQR"
                      v-if="
                        !isMobile &&
                        businessConfigurations.enableAgeRestrictedFulfillment
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="accountInfo">
                <h3 class="ellipsis" v-text="currentUserName" />
                <p class="caption ellipsis" v-text="currentUserIdentity" />
                <p>
                  <ProfileLink
                    className="btn-outline-secondaryOnBody text-caption no-underline cursor-pointer"
                  />
                </p>
                <q-btn
                  unelevated
                  no-caps
                  flat
                  color="tertiary"
                  label="Show age-verified QR"
                  class="text-underline no-hover text-caption self-start"
                  padding="0"
                  @click="openAgeVerifiedQR"
                  v-if="
                    isMobile &&
                    customer.isVerifiedOnline &&
                    businessConfigurations.isStoreAgeRestricted &&
                    businessConfigurations.enableAgeRestrictedFulfillment
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 text-right">
            <q-btn
              unelevated
              no-caps
              color="secondaryOnBody"
              label="Sign out"
              class="q-my-md"
              @click="performLogout"
              :loading="logoutInitiated"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="accountList">
          <!-- -->
          <div class="accountItem">
            <router-link
              :to="{
                name: 'Orders',
              }"
              class="accountItemInner"
            >
              <SvgIcon icon="manage-order" />
              <h4>Orders</h4>
              <ul>
                <li> View All Orders </li>
                <!-- <li>
                  <router-link
                    :to="{
                      name: 'Orders',
                      query: { tab: 'OH' },
                    }"
                  >
                    Order history</router-link
                  >
                </li> -->
              </ul>
            </router-link>
          </div>
          <!-- -->
          <!-- -->
          <div class="accountItem">
            <router-link
              :to="{ name: 'PaymentMethod' }"
              class="accountItemInner"
            >
              <SvgIcon icon="payment-delivery" />
              <h4>Payment Methods</h4>
              <ul>
                <li> Manage Payment Methods </li>
              </ul>
            </router-link>
          </div>
          <!-- -->
          <!-- -->
          <div class="accountItem">
            <router-link :to="{ name: 'Address' }" class="accountItemInner">
              <SvgIcon icon="my-address" />
              <h4>Manage Address</h4>
              <ul>
                <li> Manage Address Book </li>
              </ul>
            </router-link>
          </div>
          <!-- -->
          <!-- -->
          <div class="accountItem">
            <router-link :to="{ name: 'Favorites' }" class="accountItemInner">
              <SvgIcon icon="my-favourite" />
              <h4>Favorites</h4>
              <ul>
                <li> View Favorites </li>
              </ul>
            </router-link>
          </div>
          <!-- -->
          <!-- -->
          <!-- hide wishlist temporary -->
          <div class="accountItem">
            <router-link
              :to="{ name: 'ManageWishlist' }"
              class="accountItemInner"
            >
              <SvgIcon icon="my-wishlist" size="400" />
              <h4>Wishlist</h4>
              <ul>
                <li> View Wishlist </li>
              </ul>
            </router-link>
          </div>
          <!-- -->
          <!-- -->
          <div class="accountItem">
            <router-link :to="{ name: 'Reviews' }" class="accountItemInner">
              <SvgIcon icon="item-review" />
              <h4>Reviews</h4>
              <ul>
                <li> View Your Reviews </li>
              </ul>
            </router-link>
          </div>
          <!-- -->
          <!-- -->
          <div class="accountItem accountCloseItem" v-if="customerExists">
            <div class="accountItemInner">
              <div class="row q-col-gutter-lg items-center">
                <div class="col-12 col-md-9">
                  <q-icon name="warning_amber" size="18px" />
                  <h4>
                    Close Your Account with this store - Account Closure Is A
                    Permanent Action
                  </h4>
                  <p>
                    Please note account closure is a permanent action and once
                    your account is closed it will no longer be available to you
                    and cannot be restored. If you decide later that you want to
                    start ordering from us again, or if you would like to use
                    products and services that require an account, you will need
                    to create a new account.
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <q-btn
                    unelevated
                    outline
                    no-caps
                    color="grey-6"
                    size="16px"
                    padding="10px 10px"
                    label="Close Account"
                    class="full-width"
                    @click="closeAccount"
                    :loading="closingAccount || logoutInitiated"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'
import { hideShowFullSpinner } from 'src/utils'
import { tempCacheStorage } from 'src/boot/axios'
import IdentityMixin from 'src/utils/IdentityMixin'

export default {
  name: 'PageAccount',
  mixins: [IdentityMixin],
  meta() {
    return {
      title: 'Account Details',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      myProfileUrl: process.env.MY_PROFILE_URL,
      accountClose: false,
      closingAccount: false,
    }
  },
  computed: {
    ...mapGetters('customer', ['customer']),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  mounted() {
    this.setHeaderHeight()
  },
  methods: {
    openURL,
    closeAccount() {
      this.accountClosedConfirm().onOk(() => {
        this.closingAccount = true
        this.$store
          .dispatch('auth/deleteCustomer')
          .then((response) => {
            if (response.success && response.data) {
              this.performLogout()
            } else
              this.showError(
                "Account can't be closed due to the pending orders."
              )
          })
          .finally(() => {
            this.closingAccount = false
          })
      })
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      // if (isSuccess) setAVSessionID(eaivSessionId)
      // else {
      //   this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
      //   removeAVSessionID()
      // }

      if (!isSuccess) this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Account') {
        if (!eaivSessionId || !routeName) return
        if (isPopup) tempCacheStorage.clear()
        else this.removeQueryParams(eaivSessionId, true)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
        }, 500)
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Account') {
        if (!eaivSessionId || !routeName) return
        if (isPopup) tempCacheStorage.clear()
        else this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {},
          })
        }, 500)
      }
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
    triggerEAIVFlow() {
      hideShowFullSpinner(true)
      let reqData = {
        collectPersonalInfo: true,
        preFillInformation: this.personalInfoObj(),
        redirectUri: this.getRedirectUri,
        verificationDuring: 0,
        customerID: null,
      }

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    openAgeVerifiedQR() {
      hideShowFullSpinner(true)
      let reqData = {
        redirectUri: this.getRedirectUri,
        verificationDuring: 3,
      }

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: () => {},
                onError: () => {},
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)
  },
}
</script>
<style lang="scss">
.accountPage {
  .container {
    max-width: 1024px;
  }
  .page-title {
    padding: 30px 0;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
    @media (max-width: 599px) {
      padding: 15px 0;
    }
  }
  .accountTop {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    .accountAvatar {
      flex: 0 0 120px;
      width: 120px;
      .q-avatar {
        width: 120px;
        height: 120px;
        font-size: 45px !important;
      }

      @media (max-width: 599px) {
        flex: 0 0 90px;
        width: 90px;
        .q-avatar {
          width: 90px;
          height: 90px;
        }
      }
    }
    .accountInfo {
      flex: 10000 0 0%;
      padding-left: 30px;
      min-width: 0;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 24px;
        color: #0f141a;
        @media (max-width: 1659px) {
          font-size: 20px;
          line-height: 1.4;
          margin-bottom: 10px;
        }
      }
      p {
        font-size: 18px;
        color: #314154;
        @media (max-width: 1659px) {
          font-size: 16px;
          margin-bottom: 10px;
        }
        &.caption {
          font-size: 16px;
          color: #888888;
          @media (max-width: 1659px) {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        a {
          color: #314154;
          text-decoration: none;
          svg {
            margin-top: -3px;
          }
        }
      }
    }
  }
  .accountProfile {
    .text-right {
      .q-btn {
        margin: 0;
      }
    }
  }
}
.accountList {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  .accountItem {
    /*width: 33.3%;*/
    width: 49%;
    padding: 15px;
    @media (max-width: 599px) {
      width: 100%;
    }
    .accountItemInner {
      display: block;
      overflow: hidden;
      min-height: 150px;
      height: 100%;
      padding: 30px;
      background: #fafafa;
      border: 2px solid #dddddd;
      position: relative;
      border-radius: 10px;
      text-decoration: none;
      @media (max-width: 599px) {
        padding: 15px 20px;
        min-height: 200px;
      }

      .q-icon {
        position: absolute;
        top: 50%;
        right: 0px;
        margin-top: -50px;
        width: 170px;
        height: 170px;
        color: #888888;
        opacity: 0.1;
      }

      h4 {
        margin: 0 0 10px;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #0f141a;
        position: relative;
        z-index: 1;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        z-index: 1;
        li {
          font-size: 16px;
          padding: 5px 0;
          color: #333333;
          a {
            color: #333333;
            text-decoration: none;
            &:hover {
              color: $primary;
              color: var(--q-color-primaryOnBody) !important;
            }
          }
        }
      }
      @media (max-width: 1659px) {
        h4 {
          font-size: 18px;
        }
        ul li {
          font-size: 15px;
        }
      }
      @media (max-width: 599px) {
        h4 {
          font-size: 18px;
        }
        ul li {
          font-size: 14px;
        }
      }
    }
    &:hover {
      .accountItemInner {
        border-color: $primary;
        border-color: var(--q-color-primaryOnBody) !important;
      }
    }
    &.accountCloseItem {
      width: 100%;
      .accountItemInner {
        padding: 20px;
        padding-left: 40px;
        min-height: auto;
      }
      .q-icon {
        width: 18px;
        height: 18px;
        left: 15px;
        top: 20px;
        margin-top: 0;
        color: #b8b8b8;
        opacity: 1;
      }
      h4 {
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        color: #b8b8b8;
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
        line-height: normal;
        color: #bfbfbf;
      }
      &:hover {
        .accountItemInner {
          border-color: #dddddd !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;
    .accountItem {
      padding: 10px 15px;
      .accountItemInner {
        background: none;
        border: none;
        padding: 10px 15px 5px 15px;
        min-height: auto;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);

        h4 {
          margin-bottom: 0;
        }
        .q-icon {
          display: none;
        }
        ul {
          border-top: 1px solid #dddddd;
          // border-radius: 10px;
          li {
            padding: 15px 0;
            padding-right: 30px;
            position: relative;
            text-align: right;
            font-size: 16px;
            color: $primary;
            border-bottom: 1px solid #dddddd;
            &:last-child {
              border: none;
            }
            &:before {
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              border-right: 2px solid $primary;
              border-bottom: 2px solid $primary;
              position: absolute;
              right: 10px;
              top: 50%;
              margin-top: -3px;
              transform: rotate(-45deg);
            }
            a {
              display: block;
              padding: 15px 35px 15px 15px;
              position: relative;
              text-align: right;
              color: $primary;
              &:before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-right: 2px solid $primary;
                border-bottom: 2px solid $primary;
                position: absolute;
                right: 15px;
                top: 50%;
                margin-top: -5px;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }

      &.accountCloseItem {
        .accountItemInner {
          background: #fafafa;
          border: 2px solid #dddddd;
          box-shadow: none;
          .q-icon {
            display: block;
          }
        }
      }
    }
  }
}
</style>
